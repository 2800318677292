
import {defineComponent, PropType} from 'vue';
import {IDanhMucVatTuResponseBase} from '@/core/interfaces/ApiResponses';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {ButtonsType, ButtonTypeColors} from '@/core/enums/buttons.enums';
import {swalNotification, formatCurrencyAndQuantity, swalErrNotification} from '@/core/helpers/utils';
import { usePagination } from '../../../../../core/hooks/use-pagination';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import DanhMucVatTuService from '@/core/services/DanhMucVatTu.service';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog.vue';
import WordBreakColumn from '../../../../../components/shared/work-break-column/WordBreakColumn.vue';

export default defineComponent({
	name: 'danh-muc-vat-tu-table',

	components: {
		ButtonCustom,
		ConfirmationDialog,
		WordBreakColumn,
	},

	emits: ['delete-danh-muc-vat-tu-success'],

	data() {
		return {
			deletingDanhMucVatTu: null as IDanhMucVatTuResponseBase | null,
			ButtonsType,
			ButtonTypeColors,
			removeItemDialogDisplay: false,
		}
	},

	setup() {
		const { push } = useRouterCustom();

		const { indexCal } = usePagination();

		return {
			push,
			indexCal,
		}
	},

	props: {
		curPage: {
			type: Number,
			default: 0,
		},
		items: Array as PropType<Array<IDanhMucVatTuResponseBase>>,
		loading: {
			type: Boolean,
			default: false,
		},
		perPage: {
			type: Number,
			default: 0,
		},
	},

	methods: {
		formatCurrencyAndQuantity,

		onDeleteButtonClicked(danhMucVatTuId: number) {
			const danhMucVatTu = this.items?.find(item => item.id === danhMucVatTuId);
			this.removeItemDialogDisplay = true;
			if (danhMucVatTu) {
				this.deletingDanhMucVatTu = danhMucVatTu;
			}
		},

		async confirmDelete() {
			this.removeItemDialogDisplay = false;
			try {
				if (this.deletingDanhMucVatTu) {
					await DanhMucVatTuService.delete(this.deletingDanhMucVatTu?.id);
				}
				await swalNotification(
					'Xóa thành công',
					'success'
				);
				this.$emit('delete-danh-muc-vat-tu-success');
			} catch (error) {
				await swalErrNotification(error, 'Có lỗi xảy ra, không thể xóa D/mục vật tư này');
			}
		}
	}
})
