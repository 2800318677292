
import {defineComponent, ref} from 'vue';
import {IDanhMucVatTuResponseBase} from '@/core/interfaces/ApiResponses';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {swalNotification} from '@/core/helpers/utils';
import {usePagination} from '@/core/hooks/use-pagination';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import { ButtonsType, ButtonTypeColors } from '@/core/enums/buttons.enums';

import DanhMucVatTuService from '@/core/services/DanhMucVatTu.service';
import DanhMucVatTuTable from '@/views/crafted/pages/danh-muc-vat-tu/danh-muc-vat-tu-table/DanhMucVatTuTable.vue';
import PerPageSelection from '@/components/shared/per-page-selection/PerPageSelection.vue';
import Pagination from '@/components/shared/pagination/Pagination.vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';

export default defineComponent({
	name: 'danh-muc-vat-tu-danh-sach',

	components: {PerPageSelection, PageRowLayout, DanhMucVatTuTable, Pagination, ButtonCustom },

	data() {
		return {
			ButtonsType,
			ButtonTypeColors
		}
	},

	setup() {
		const { push } = useRouterCustom()
		const { currentPage, totalItems, perPage, loading, perPageArr } = usePagination();
		const danhMucVatTuItems = ref<IDanhMucVatTuResponseBase[]>([]);

		return {
			push, danhMucVatTuItems, currentPage, totalItems, perPage, loading, perPageArr
		}
	},

	watch: {
		async currentPage() {
			await this.fetchData();
		},

		async perPage() {
			await this.fetchData();
		}
	},

	async mounted() {
		setCurrentPageTitle('Danh mục vật tư');

		await this.fetchData();
	},

	methods: {
		async fetchData() {
			try {
				this.loading = true;

				const { data: { data: { data: res, total } } } = await DanhMucVatTuService.list(
					this.currentPage, this.perPage);

				this.totalItems = total;

				this.danhMucVatTuItems = res;

				this.loading = false;
			} catch (e) {
        this.loading = false;

				await swalNotification(
					'Có lỗi xảy ra khi lấy danh sách danh mục vật tư',
					'error',
				);
				this.loading = false;
			}
		}
	}
})
