import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_custom = _resolveComponent("button-custom")!
  const _component_PerPageSelection = _resolveComponent("PerPageSelection")!
  const _component_DanhMucVatTuTable = _resolveComponent("DanhMucVatTuTable")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_PageRowLayout = _resolveComponent("PageRowLayout")!

  return (_openBlock(), _createBlock(_component_PageRowLayout, null, {
    "page-row-header": _withCtx(() => [
      _createTextVNode(" Danh sách ")
    ]),
    "page-row-action-button": _withCtx(() => [
      _createVNode(_component_button_custom, {
        "color-type": _ctx.ButtonTypeColors.PRIMARY,
        "button-type": _ctx.ButtonsType.ADD,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.push('/quan-ly/danh-muc-vat-tu/tao-moi')))
      }, null, 8, ["color-type", "button-type"])
    ]),
    "page-row-content": _withCtx(() => [
      _createVNode(_component_PerPageSelection, {
        "per-page-arr": _ctx.perPageArr,
        "per-page": _ctx.perPage,
        onChange: _cache[1] || (_cache[1] = (value) => _ctx.perPage = value)
      }, null, 8, ["per-page-arr", "per-page"]),
      _createVNode(_component_DanhMucVatTuTable, {
        "cur-page": _ctx.currentPage,
        items: _ctx.danhMucVatTuItems,
        loading: _ctx.loading,
        "per-page": _ctx.perPage,
        onDeleteDanhMucVatTuSuccess: _ctx.fetchData
      }, null, 8, ["cur-page", "items", "loading", "per-page", "onDeleteDanhMucVatTuSuccess"]),
      (_ctx.danhMucVatTuItems.length > 0)
        ? (_openBlock(), _createBlock(_component_Pagination, {
            key: 0,
            page: _ctx.currentPage,
            "page-size": _ctx.perPage,
            total: _ctx.totalItems,
            onChangePage: _cache[2] || (_cache[2] = (value) => _ctx.currentPage = value)
          }, null, 8, ["page", "page-size", "total"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}